<template>
  <div id="app">
    <Game />
  </div>
</template>

<script>
import Game from './components/GameCore.vue';

export default {
  name: 'App',
  components: {
    Game
  }
};
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>