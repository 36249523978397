<template>
  <div ref="gameContainer" :style="gameContainerStyle" class="game-container" @keydown="handleKeyDown" tabindex="0">
    <div class="lives">Lives: {{ lives }}</div>
    <div :style="vehicleStyle" class="vehicle"></div>
    <div v-for="(obstacle, index) in obstacles" :key="index" :style="getObstacleStyle(obstacle)" class="obstacle"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

// Constants
const GAME_WIDTH = 400;
const GAME_HEIGHT = 600;
const VEHICLE_WIDTH = 40;
const VEHICLE_HEIGHT = 60;
const VEHICLE_SPEED = 5;
const OBSTACLE_WIDTH = 40;
const OBSTACLE_HEIGHT = 60;
const OBSTACLE_SPEED = 3;
const BACKGROUND_SPEED = 2;

// Vehicle position
const vehicleX = ref(GAME_WIDTH / 2 - VEHICLE_WIDTH / 2);

// Obstacles
const obstacles = ref([]);

// Lives
const lives = ref(3);

// Background position
const backgroundY = ref(0);

// Reference to the game container
const gameContainer = ref(null);

// Computed style for the vehicle
const vehicleStyle = computed(() => ({
  width: `${VEHICLE_WIDTH}px`,
  height: `${VEHICLE_HEIGHT}px`,
  position: 'absolute',
  bottom: '20px',
  left: `${vehicleX.value}px`,
  backgroundImage: 'url(/assets/Race4_vehicle.png)',
  backgroundSize: 'cover',
}));

// Computed style for obstacles
const getObstacleStyle = (obstacle) => ({
  width: `${obstacle.width || OBSTACLE_WIDTH}px`,
  height: `${obstacle.height || OBSTACLE_HEIGHT}px`,
  position: 'absolute',
  top: `${obstacle.y}px`,
  left: `${obstacle.x}px`,
  backgroundImage: obstacle.type === 'rock' 
    ? 'url(/assets/Race1_obstacles_make-damage_rocks.png)' 
    : obstacle.type === 'cone' 
      ? 'url(/assets/Race4_obstacles_slower_streetsign.png)' 
      : 'url(/assets/Race3_obstacles_make-damage_wood.png)',
  backgroundSize: 'cover',
});

// Handle keydown events to move the vehicle left or right
const handleKeyDown = (event) => {
  if (event.key === 'ArrowLeft') {
    vehicleX.value = Math.max(vehicleX.value - VEHICLE_SPEED, 0);
  } else if (event.key === 'ArrowRight') {
    vehicleX.value = Math.min(vehicleX.value + VEHICLE_SPEED, GAME_WIDTH - VEHICLE_WIDTH);
  }
};

// Add new obstacles at random positions
const addObstacle = () => {
  const xPosition = Math.floor(Math.random() * (GAME_WIDTH - OBSTACLE_WIDTH));
  const type = Math.random() < 0.5 ? 'rock' : 'cone';
  obstacles.value.push({ x: xPosition, y: -OBSTACLE_HEIGHT, type });
};

// Update obstacles' positions
const updateObstacles = () => {
  obstacles.value.forEach((obstacle) => {
    obstacle.y += OBSTACLE_SPEED;
  });
  // Remove obstacles that are out of the game area
  obstacles.value = obstacles.value.filter((obstacle) => obstacle.y < GAME_HEIGHT);
};

// Update background position
const updateBackground = () => {
  backgroundY.value = (backgroundY.value + BACKGROUND_SPEED) % GAME_HEIGHT;
};

// Computed style for the game container

// Check for collisions
const checkCollisions = () => {
  obstacles.value.forEach((obstacle) => {
    const vehicleTop = GAME_HEIGHT - VEHICLE_HEIGHT - 20;
    const vehicleBottom = GAME_HEIGHT - 20;
    const vehicleLeft = vehicleX.value;
    const vehicleRight = vehicleX.value + VEHICLE_WIDTH;

    const obstacleTop = obstacle.y;
    const obstacleBottom = obstacle.y + OBSTACLE_HEIGHT;
    const obstacleLeft = obstacle.x;
    const obstacleRight = obstacle.x + OBSTACLE_WIDTH;

    const isColliding =
      vehicleBottom > obstacleTop &&
      vehicleTop < obstacleBottom &&
      vehicleRight > obstacleLeft &&
      vehicleLeft < obstacleRight;

    if (isColliding) {
      lives.value -= 1;
      obstacle.y = GAME_HEIGHT; // Move the obstacle out of the game area
    }
  });
};

// Game loop
const gameLoop = () => {
  if (lives.value > 0) {
    updateObstacles();
    updateBackground();
    checkCollisions();
    if (Math.random() < 0.02) {
      addObstacle();
    }
    requestAnimationFrame(gameLoop);
  } else {
    alert('Game Over');
  }
};

// Focus the game container when the component is mounted
onMounted(() => {
  if (gameContainer.value) {
    gameContainer.value.focus();
  }
  gameLoop();
});
// Computed style for the game container including the moving background
const gameContainerStyle = computed(() => ({
  width: `${GAME_WIDTH}px`,
  height: `${GAME_HEIGHT}px`,
  backgroundImage: 'url(/assets/Race4_BG.png)',
  backgroundSize: 'cover',
  backgroundPositionY: `${backgroundY.value}px`,
  position: 'relative',
  overflow: 'hidden',
  outline: 'none',
}));

</script>

<style scoped>
.game-container {
  width: 400px;
  height: 600px;
  position: relative;
  overflow: hidden;
  outline: none;
}

.lives {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  color: white;
}

.vehicle {
  transition: left 0.05s;
}

.obstacle {
  width: 40px;
  height: 60px;
  position: absolute;
  background-size: cover;
}
</style>
